import React from 'react'

import ProgressBar from './ProgressBar'
import AccountForm from '../content/account-form.types'
import { Lang } from '../common-types'
import { generateId } from '../helpers'

import Logo from '../images/logo.inline.svg'

import styles from './Header.module.scss'

interface Props {
  step: number
  accountForm: AccountForm
  lang: Lang
  className?: string
}

const id = generateId(6)

const Header: React.FC<Props> = ({
  step,
  accountForm,
  lang,
  className,
}: Props) => {
  const [currentStep, ofSteps] = accountForm.stepsText[lang].split('%step%')
  const currentStepText = `${currentStep} ${step}`
  const ofStepsText = ofSteps.replace('%steps%', '6')
  const stepsText = `${currentStepText} ${ofStepsText}`
  return (
    <header className={`${styles.header} ${className}`.trim()}>
      <ProgressBar
        step={step}
        stepsText={stepsText}
        stepsAriaLabel={accountForm.stepsAriaLabel[lang]}
        className={styles.progressBar}
      />
      <a
        href="https://rentaphoto.com"
        aria-labelledby={id}
        className={styles.logo}
      >
        <span id={id} aria-label={accountForm.gotoMainPage[lang]} />
        <Logo aria-label={accountForm.logo[lang]} role="img" />
      </a>
      {step > 0 && step < 7 && (
        <p className={styles.stepsText}>
          <span className={styles.currentStep}>{currentStepText}</span>
          <span className={styles.ofSteps}>{ofStepsText}</span>
        </p>
      )}
    </header>
  )
}

export default Header
