export const BASE_URL = 'https://anketa.rentaphoto.com'
// export const BASE_URL = 'http://localhost:3000'
export const UPLOADS_FIELD_NAME = 'upload'
export const UPLOADS_ENDPOINT = `${BASE_URL}/uploads`
export const DOCUMENTS_REL_PATH = '/documents'
export const SUBMIT_ENDPOINT = `${BASE_URL}/submit`
export const SUBMIT_STATUS = `${BASE_URL}/status`
export const TOKEN_ENDPOINT = `${BASE_URL}/token`
export const ERRORS_ENDPOINT = `${BASE_URL}/error`
export const FORM_EXISTS_ENDPOINT = `${BASE_URL}/form-exists/`
export const USER_EXISTS_ENDPOINT = `${BASE_URL}/user-exists/`
export const SUBMIT_SUCCESS = `${BASE_URL}/success`
