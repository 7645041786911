import { useRef, useEffect } from 'react'
import axios from 'axios'

import { State } from './state-actions-reducers'
import { ERRORS_ENDPOINT } from './rentaphoto-server-parameters'

interface ErrorReport {
  message: string
  step?: number
  userId?: string
  formId?: string
  additional?: unknown
}
export function reportError(error: ErrorReport) {
  console.error(error)
  axios.post(ERRORS_ENDPOINT, { ...error })
}

function getRandomInt(max: number, min = 0): number {
  /**
   * Returns a random integer between min (inclusive) and max (inclusive).
   * The value is no lower than min (or the next integer greater than min
   * if min isn't an integer) and no greater than max (or the next integer
   * lower than max if max isn't an integer).
   * Using Math.round() will give you a non-uniform distribution!
   */
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function generateId(length = 8) {
  // Generate random alphabetical string in non secure manner
  const dictionary = 'abcdefghijklmnopqrstuvwxyz'
  let result = ''
  for (let i = 0; i < length; i++) {
    result += dictionary.charAt(getRandomInt(25))
  }
  return result
}

export function zeroPaddedNumber(num: number): string {
  if (isNaN(num)) {
    throw new TypeError('Invalid argument. Must be number')
  }
  const sign = num < 0 ? '-' : ''
  return Math.abs(num) < 10 ? `${sign}0${Math.abs(num)}` : String(num)
}

export function yearsAgo(years: number): Date {
  const now = new Date()
  now.setFullYear(now.getFullYear() - years)
  return now
}

export function daysAgo(days: number): Date {
  const now = new Date()
  now.setDate(now.getDate() - days)
  return now
}

export function isoDate(date: Date): string {
  const year = date.getFullYear()
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  return `${year}-${month}-${day}`
}

export function fromISOdate(date: string): Date {
  const year = parseInt(date.substr(0, 4), 10)
  const month = parseInt(date.substr(5, 2), 10) - 1
  const day = parseInt(date.substr(8), 10)
  return new Date(year, month, day)
}

export function parseDate(date: Date | string): Date | null {
  if (typeof date === 'string') {
    if (date.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return fromISOdate(date)
    }
  }
  if (date instanceof Date) {
    return date
  }
  return null
}

export function getDaysInMonth(
  monthOrDate: number | Date,
  year?: number
): number {
  if (monthOrDate instanceof Date) {
    return new Date(
      monthOrDate.getFullYear(),
      monthOrDate.getMonth(),
      0
    ).getDate()
  }
  if (!year || isNaN(year)) {
    throw new Error('Must provide year: number to getDaysInMonth')
  }
  return new Date(year, monthOrDate, 0).getDate()
}

interface PassportInputHandlerArgs {
  newValue: string
  oldValue: string
  isRussianPassport: boolean
  kind: 'id' | 'authority'
}
export function passportInputHandler({
  newValue,
  oldValue,
  isRussianPassport,
  kind,
}: PassportInputHandlerArgs): string {
  if (isRussianPassport) {
    // For Russian passport authority code try
    // to make it look like 111-111
    // For russian passport id make it look like 111 111

    let regexp = kind === 'id' ? /^\d{3}\s{1}\d{0,3}$/ : /^\d{3}-{1}\d{0,3}$/
    // General case. New value matches code, so do nothing
    if (newValue.match(regexp)) return newValue

    // If new value is like 123-4567...
    regexp = kind === 'id' ? /^\d{3}\s{1}\d{3,}$/ : /^\d{3}-{1}\d{3,}$/
    // Don't allow add new digits
    if (newValue.length > 7 && newValue.match(regexp)) {
      return oldValue.substr(0, 7)
    }

    // If new value looks like: 123-?
    regexp = kind === 'id' ? /^\d{3}\s\D$/ : /^\d{3}-\D$/
    if (newValue.match(regexp)) return newValue.substr(0, 4)

    // If new value looks like 123 and prev value was 12
    if (newValue.match(/\d{3}/) && oldValue.length === 2) {
      return kind === 'id' ? `${newValue} ` : `${newValue}-`
    }

    // If new value looks like: 1234
    if (newValue.length > 2) {
      const digits = newValue.replace(/[\D]/gim, '')
      const result = `${digits.substr(0, 3)} ${digits.substr(3, 3)}`.trim()
      return kind === 'id' ? result : result.replace(' ', '-')
    }

    // If new value looks like: 12
    return newValue.replace(/[\D]/gim, '').substr(-6)
  }

  // For non russian passport allow freely use all characters
  return newValue
}

export function copyFromRegistered(state: State) {
  return {
    ...state,
    addressFactualCountry: state.addressRegisteredCountry,
    addressFactualRegion: state.addressRegisteredRegion,
    addressFactualCity: state.addressRegisteredCity,
    addressFactualStreet: state.addressRegisteredStreet,
    addressFactualBuilding: state.addressRegisteredBuilding,
    addressFactualApartments: state.addressRegisteredApartments,
  }
}

export function copyFromFactual(state: State) {
  return {
    ...state,
    addressRegisteredCountry: state.addressFactualCountry,
    addressRegisteredRegion: state.addressFactualRegion,
    addressRegisteredCity: state.addressFactualCity,
    addressRegisteredStreet: state.addressFactualStreet,
    addressRegisteredBuilding: state.addressFactualBuilding,
    addressRegisteredApartments: state.addressFactualApartments,
  }
}

// Custom react hook for deffered function execution
interface SomeCallback {
  (a?: unknown): unknown
}
const DEFER_TIME = 1200
export function useDefer(
  callback: SomeCallback,
  deps: unknown[],
  timeout = DEFER_TIME
): void {
  const changesQueue = useRef(0)
  useEffect(() => {
    changesQueue.current += 1
    setTimeout(() => {
      changesQueue.current -= 1
      if (changesQueue.current === 0) {
        callback()
      }
    }, timeout)
  }, deps)
}

export async function request(
  url: string,
  payload: { [key: string]: unknown },
  onErrorMessage?: string
): Promise<string | null> {
  try {
    const response = await axios.post(url, payload)
    const {
      ok,
      message,
      data,
    }: { ok: boolean; message: string; data: string } = response.data
    if (!ok) {
      throw new Error(message)
    }
    return data
  } catch (error) {
    if (url !== ERRORS_ENDPOINT) {
      reportError({
        message: onErrorMessage || `Error while fetching: ${url}`,
        additional: { payload, error },
      })
    } else {
      console.log('Error during error report')
      console.error(error)
    }
    return null
  }
}
