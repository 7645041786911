import AccountForm from './account-form.types'

const accountForm: AccountForm = {
  title: {
    eng: 'INQUIRY FORM for a Customer card',
    rus: 'Анкета на получение карты клиента',
  },
  404: {
    eng: '',
    rus: 'К сожалению, страница не найдена%button%Вернуться к началу%button%',
  },
  logo: {
    eng: 'Rentaphoto logo',
    rus: 'Логотип Rentaphoto',
  },
  yes: {
    eng: 'yes',
    rus: 'да',
  },
  no: {
    eng: 'no',
    rus: 'нет',
  },
  stepsText: {
    eng: 'Step %step% of %steps%',
    rus: 'Шаг %step% из %steps%',
  },
  stepsAriaLabel: {
    eng: '',
    rus: 'Прогресс заполнения анкеты',
  },
  prevButton: {
    eng: 'Previous',
    rus: 'Назад',
  },
  nextButton: {
    eng: 'Next',
    rus: 'Вперёд',
  },
  submitButton: {
    eng: 'Submit',
    rus: 'Отправить анкету',
  },
  addFieldButton: {
    eng: 'Add another one',
    rus: 'Добавить ещё',
  },
  removeFieldButton: {
    eng: 'Remove',
    rus: 'Удалить',
  },
  referencesInputText: {
    label: {
      eng: 'How did you get to know about us',
      rus: 'Откуда вы узнали о нас (выберите, пожалуйста, все релевантные)?',
    },
    placeholder: {
      eng: '',
      rus: 'Выберите из списка или введите свой вариант',
    },
  },
  summaryReferencesLabel: {
    eng: '',
    rus: 'Откуда вы узнали о нас',
  },
  referencesNoOptions: {
    eng: '',
    rus: 'Введите свой вариант',
  },
  referencesCreateOption: {
    eng: '',
    rus: 'Добавить',
  },
  createOption: {
    eng: '',
    rus: 'Добавить',
  },
  referencesInputValues: [
    {
      eng: '',
      rus: 'google',
    },
    {
      eng: '',
      rus: 'yandex',
    },
    {
      eng: '',
      rus: 'рекомендации друзей',
    },
    {
      eng: '',
      rus: 'рекомендации других ренталов',
    },
    {
      eng: '',
      rus: 'соцсети',
    },
    {
      eng: '',
      rus: 'выставки и мероприятия',
    },
  ],
  additionalInfoTitle: {
    eng: '',
    rus: 'Дополнительная информация',
  },
  additionalInfoNote: {
    eng: '',
    rus:
      'Важно: при указании ваших соцсетей, нам будет проще одобрить вам больший лимит.',
  },
  socialLinks: [
    {
      label: {
        eng: '',
        rus: 'VK',
      },
      placeholder: {
        eng: '',
        rus: 'https://vk.com/...',
      },
    },
    {
      label: {
        eng: '',
        rus: 'Facebook',
      },
      placeholder: {
        eng: '',
        rus: 'https://facebook.com/...',
      },
    },
    {
      label: {
        eng: '',
        rus: 'Youtube',
      },
      placeholder: {
        eng: '',
        rus: 'https://youtube.com/...',
      },
    },
    {
      label: {
        eng: '',
        rus: 'Vimeo',
      },
      placeholder: {
        eng: '',
        rus: 'https://vimeo.com/...',
      },
    },
    {
      label: {
        eng: '',
        rus: 'Instagram',
      },
      placeholder: {
        eng: '',
        rus: 'https://www.instagram.com/...',
      },
    },
    {
      label: {
        eng: '',
        rus: 'Рабочий сайт',
      },
      placeholder: {
        eng: '',
        rus: 'https://...',
      },
    },
    {
      label: {
        eng: '',
        rus: 'Персональный сайт',
      },
      placeholder: {
        eng: '',
        rus: 'https://...',
      },
    },
  ],
  additionalInfoExtraInput: {
    label: {
      eng: '',
      rus: 'Другое (здесь можно добавить ссылки на "файловое хранилище")',
    },
    placeholder: {
      eng: '',
      rus: 'https://',
    },
  },
  firstName: {
    label: {
      eng: 'First name',
      rus: 'Имя',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  lastName: {
    label: {
      eng: 'Last name',
      rus: 'Фамилия',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  patronymic: {
    label: {
      eng: 'Patronymic',
      rus: 'Отчество',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  occupationInstitution: {
    label: {
      eng: 'Company/university',
      rus: 'Место работы',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  occupationPosition: {
    label: {
      eng: 'Occupation/position',
      rus: 'Профессия (занимаемая должность)',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  workPhoneNumber: {
    label: {
      eng: 'Work phone number',
      rus: 'Рабочий телефон',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  monthlyIncomeInputText: {
    label: {
      eng: 'Monthly income',
      rus: 'Ежемесячный доход',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  monthlyIncomeInputValues: [
    {
      eng: '',
      rus: 'до 30 000',
    },
    {
      eng: '',
      rus: '31 000 - 70 000',
    },
    {
      eng: '',
      rus: '71 000 - 100 000',
    },
    {
      eng: '',
      rus: '101 000 - 150 000',
    },
    {
      eng: '',
      rus: '151 000 - 250 000',
    },
    {
      eng: '',
      rus: '251 000 - 350 000',
    },
    {
      eng: '',
      rus: '351 000 - 1 000 000',
    },
    {
      eng: '',
      rus: 'более 1 000 000',
    },
  ],
  isStudent: {
    label: {
      eng: '',
      rus: 'Являюсь студентом/студенткой',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  collegeName: {
    label: {
      eng: '',
      rus: 'Название учебного заведения',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  collegeProfession: {
    label: {
      eng: '',
      rus: 'Специальность',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  collegeStartedAt: {
    label: {
      eng: '',
      rus: 'Начало обучения',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  collegeEndedAt: {
    label: {
      eng: '',
      rus: 'Конец обучения',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  mobilePhoneNumber: {
    label: {
      eng: 'Mobile phone number',
      rus: 'Мобильный телефон',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  addressRegisteredPhone: {
    label: {
      eng: '',
      rus: 'Телефон по месту прописки (городской)',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  addressFactualPhone: {
    label: {
      eng: '',
      rus: 'Телефон по месту проживания (городской)',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  email: {
    label: {
      eng: 'E-mail',
      rus: 'E-mail',
    },
    placeholder: {
      eng: '',
      rus: 'Введите действующий email',
    },
  },
  emailConfirmationCode: {
    label: {
      eng: '',
      rus: 'Код подтверждения email адреса',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  emailConfirmationMessage: {
    eng: '',
    rus:
      'Мы отправили, на указанный адрес, письмо с кодом подтверждения. Пожалуйста, проверьте почу (включая спам) и введите код.%repeat%Повторная отправка возможна через %minutes%:%seconds%.',
  },
  emailConfirmationRepeat: {
    eng: '',
    rus: 'отправить заново',
  },
  agreementHeader: {
    eng: '',
    rus: 'АНКЕТА НА ПОЛУЧЕНИЕ КАРТЫ КЛИЕНТА',
  },
  agreementsDescription: {
    eng: '',
    rus: 'Прежде чем начать, ознакомьтесь, пожалуйста, с',
  },
  agreementShowBtnTip: {
    eng: '',
    rus: 'Нажмите, чтобы прочесть',
  },
  agreementButtonConfurmLabel: {
    eng: '',
    rus: 'Я подтверждаю своё согласие на обработку моих персональных данных',
  },
  agreementButtonConfurm: {
    eng: '',
    rus: 'Подтверждаю',
  },
  gotoMainPage: {
    eng: '',
    rus: 'Вернуться на главную',
  },
  citizenshipInput: {
    label: {
      eng: 'Citizenship',
      rus: 'Гражданство',
    },
    noOptionsMessage: {
      eng: 'not found',
      rus: 'не найдено',
    },
    placeholder: {
      eng: '...select',
      rus: '...выберите страну',
    },
  },
  passportIAt: {
    label: {
      eng: 'Date of issue',
      rus: 'Дата выдачи',
    },
    placeholder: {
      eng: 'Press here to pick the date',
      rus: 'Нажмите, чтобы выбрать дату',
    },
  },
  birthDate: {
    label: {
      eng: 'Date of birth',
      rus: 'Дата рождения',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  birthPlace: {
    label: {
      eng: 'Place of birth',
      rus: 'Место рождения',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  passportSeries: {
    label: {
      eng: '',
      rus: 'Серия',
    },
    placeholder: {
      eng: '',
      rus: 'Введите серию документа',
    },
  },
  passportNumber: {
    label: {
      eng: '',
      rus: 'Номер',
    },
    placeholder: {
      eng: '',
      rus: 'Введите номер документа',
    },
  },
  passportIssuedBy: {
    label: {
      eng: 'Authority',
      rus: 'Кем выдан',
    },
    placeholder: {
      eng: '',
      rus: 'Введите название',
    },
  },
  passportAuthorityCode: {
    label: {
      eng: '',
      rus: 'Код подразделения',
    },
    placeholder: {
      eng: '',
      rus: 'Введите код подразделения',
    },
  },
  passportTypeInput: {
    label: {
      eng: '',
      rus: 'Вид удостоверения личности (можно указать свой вариант)',
    },
    placeholder: {
      eng: '',
      rus: 'Выберите или введите свой вариант',
    },
  },
  passportTypeValues: [
    {
      eng: '',
      rus: 'Паспорт гражданина РФ',
    },
  ],
  noOptionsMessage: {
    eng: 'not found',
    rus: 'не найдено',
  },
  addressRegisteredDate: {
    label: {
      eng: '',
      rus: 'Дата прописки',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  addressCountry: {
    label: {
      eng: '',
      rus: 'Страна',
    },
    noOptionsMessage: {
      eng: 'not found',
      rus: 'не найдено',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  addressRegion: {
    label: {
      eng: '',
      rus: 'Регион',
    },
    placeholder: {
      eng: '',
      rus: 'Название региона, области',
    },
  },
  addressCity: {
    label: {
      eng: '',
      rus: 'Населённый пункт',
    },
    placeholder: {
      eng: '',
      rus: 'Город, посёлок, село...',
    },
  },
  addressStreet: {
    label: {
      eng: '',
      rus: 'Улица',
    },
    placeholder: {
      eng: '',
      rus: 'Улица, бульвар, шоссе...',
    },
  },
  addressBuilding: {
    label: {
      eng: '',
      rus: '№ Дома (строение, корпус)',
    },
    placeholder: {
      eng: '',
      rus: '№ дома, строение...',
    },
  },
  addressApartments: {
    label: {
      eng: '',
      rus: '№ Квартиры',
    },
    placeholder: {
      eng: '',
      rus: 'Введите номер квартиры',
    },
  },
  recommendationsName: {
    label: {
      eng: '',
      rus: 'ФИО',
    },
    placeholder: {
      eng: '',
      rus: 'Введите Фамилию Имя и Отчество',
    },
  },
  recommendationsPhone: {
    label: {
      eng: '',
      rus: 'Номер телефона',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  subscribe: {
    label: {
      eng: '',
      rus: 'Подписаться на рассылку',
    },
    placeholder: {
      eng: '',
      rus: '',
    },
  },
  passportTitle: {
    eng: 'Document',
    rus: 'Удостоверение личности',
  },
  addressRegisteredTitle: {
    eng: 'Registered address',
    rus: 'Адрес прописки',
  },
  addressFactualTitle: {
    eng: 'Residential address',
    rus: 'Адрес проживания',
  },
  addressRegisteredCopyBtnDesc: {
    eng: '',
    rus: 'Скопировать адрес проживания',
  },
  addressFactualCopyBtnDesc: {
    eng: '',
    rus: 'Скопировать адрес прописки',
  },
  addressCopyButton: {
    eng: '',
    rus: 'Копировать',
  },
  uploadPassportHeader: {
    eng: '',
    rus: 'Копии страниц удостоверения личности',
  },
  uploadPassportTextNonRussian: {
    eng: '',
    rus:
      'Загрузите копии страниц документа, удостоверяющего личность. Мы ожидаем страницы с фотографией, адресом проживания, информацией о ранее выданных паспортах, если такие страницы имеются.',
  },
  uploadPassportTextRussian: {
    eng: '',
    rus:
      'Загрузите копии следующих страниц:%li%Разворот 2-3 страниц паспорта (с фотографией)%li%Разворот страниц с действующей регистрацией%li%Разворот страниц с информацией о ранее выданных паспортах (18-19 стр), даже если страницы пустые',
  },
  uploadDeleteButton: {
    eng: 'Delete all images',
    rus: 'Удалить все изображения',
  },
  uploadErrorId0: {
    eng: 'Images must be less than 10 megabytes.',
    rus: 'Изображения должны быть меньше 10 мегабайтов.',
  },
  uploadErrorId1: {
    eng: 'Only .jpg, .png and .bmp files are supported',
    rus: 'Изображения должны быть в формате .jpg или .png, или .bmp.',
  },
  uploadErrorId2: {
    eng:
      'Internal server error. Please, try again later or send scans by email:',
    rus:
      'Ошибка сервера. Пожалуйста, попробуйте позже или отправьте файлы на email:',
  },
  recommendationsLabel: {
    eng: '',
    rus: 'Может ли кто-либо из наших клиентов порекомендовать вас?',
  },
  uploadPortfolioHeader: {
    eng: '',
    rus: 'Портфолио',
  },
  uploadPortfolioText: {
    eng: '',
    rus:
      'Вы можете приложить к анкете примеры ваших работ, если их нельзя посмотреть в интернете.',
  },
  almostThereMessage: {
    eng: '',
    rus:
      'Почти закончили! Осталось проверить, всё ли верно и отправить анкету.',
  },
  checkAndSubmitBtn: {
    eng: '',
    rus: 'Проверить и отправить',
  },
  summaryGoToButton: {
    eng: '',
    rus: 'Вернуться',
  },
  submitLoading: {
    eng: '',
    rus: 'Анкета отправляется, подождите...',
  },
  validationError: {
    eng: '',
    rus:
      'Одно или несколько полей заполнены с ошибками. Они выделены цветом. Исправьте ошибки, чтобы продолжить.',
  },
  validationNoImagesError: {
    eng: '',
    rus: 'Нужно обязательно загрузить изображения',
  },
  summaryNoImagesMessage: {
    eng: '',
    rus: 'Нет загруженных изображений',
  },
  submitSucess: {
    eng: '',
    rus:
      'Ваша Анкета клиента RENTAPHOTO получена и уже находится на рассмотрении!\nОформление и рассмотрение Вашей Анкеты после подтверждения данных займет 1 рабочий день, если предоставленной информации достаточно для принятия решения о лимите покрытия и она является достоверной.\nВАЖНО: если данных в анкете недостаточно для принятия решения о лимите покрытия – мы свяжемся с Вами для уточнения информации. В этом случае рассмотрение анкеты может занять до 4-х суток.\n\nМы обязательно вышлем Вам информацию по лимиту на Ваш электронный адрес!',
  },
  submitSucessHeader: {
    eng: '',
    rus: 'Анкета успешно отправлена',
  },
  submitError: {
    eng: '',
    rus:
      'Произошла ошибка и анкета не отправлена. Попробуйте ещё раз через 10 минут (заполнять заново не нужно). Пожалуйста, если эта ошибка повторяется, сообщите нам по телефону %tel%+7 (499) 647 55 61%tel% или email%email%service@rentaphoto.com%email%.',
  },
  refillFormButton: {
    eng: '',
    rus: 'Заполнить ещё одну анкету',
  },
  workWebsite: {
    label: {
      eng: '',
      rus: 'Рабочий сайт',
    },
    placeholder: {
      eng: '',
      rus: 'https://...',
    },
  },
  instagram: {
    label: {
      eng: '',
      rus: 'Instagram',
    },
    placeholder: {
      eng: '',
      rus: 'https://www.instagram.com/...',
    },
  },
  vimeo: {
    label: {
      eng: '',
      rus: 'Vimeo',
    },
    placeholder: {
      eng: '',
      rus: 'https://vimeo.com/...',
    },
  },
  youtube: {
    label: {
      eng: '',
      rus: 'Youtube',
    },
    placeholder: {
      eng: '',
      rus: 'https://youtube.com/...',
    },
  },
  additionalLinks: {
    label: {
      eng: '',
      rus: 'Другое (здесь можно добавить ссылки на "файловое хранилище")',
    },
    placeholder: {
      eng: '',
      rus: 'https://',
    },
  },
  personalWebsite: {
    label: {
      eng: '',
      rus: 'Персональный сайт',
    },
    placeholder: {
      eng: '',
      rus: 'https://...',
    },
  },
  vk: {
    label: {
      eng: '',
      rus: 'VK',
    },
    placeholder: {
      eng: '',
      rus: 'https://vk.com/...',
    },
  },
  summaryPersonalDataTitle: {
    eng: '',
    rus: 'Личные данные',
  },
  facebook: {
    label: {
      eng: '',
      rus: 'Facebook',
    },
    placeholder: {
      eng: '',
      rus: 'https://facebook.com/...',
    },
  },
  feedback: {
    label: {
      eng: '',
      rus: 'Все, что еще вы хотели бы нам сообщить',
    },
    placeholder: {
      eng: '',
      rus: 'Напишите здесь...',
    },
  },
  closeButton: {
    eng: '',
    rus: 'Закрыть',
  },
  goBackButton: {
    eng: '',
    rus: 'Назад',
  },
  acceptButton: {
    eng: '',
    rus: 'Принять',
  },
  resetStateButton: {
    eng: '',
    rus: 'Сбросить всё',
  },
  resetStateWarning: {
    eng: '',
    rus:
      'Это действие удалит всю введённую вами информацию. Если вы действительно хотите это сделать, нажмите на кнопку ещё раз.',
  },
  selectYear: {
    eng: '',
    rus: 'Выберите год',
  },
  selectMonth: {
    eng: '',
    rus: 'Выберите месяц',
  },
  selectDay: {
    eng: '',
    rus: 'Выберите день',
  },
}

export default accountForm
