import React from 'react'

interface Props {
  step: number
  stepsText: string
  stepsAriaLabel: string
  className?: string
}

const activeColor = '#f15b3a'
const defaultColor = '#b3b2b1'

const ProgressBar = ({ step, stepsText, stepsAriaLabel, className }: Props) => {
  if (step === 0) return null
  if (step > 6) return null
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 430 103.99999"
      aria-label={`${stepsAriaLabel}. ${stepsText}`}
      className={className}
    >
      {/* Second line */}
      <path
        d="M 44.455888,76.320979 H 39.847889 V 0 h 4.607999 z"
        fill={step === 1 ? activeColor : defaultColor}
      />
      {/* First line */}
      <path
        d="M 6.6078981,76.320979 H 1.9998994 V 35.17899 h 4.6079987 z"
        fill={defaultColor}
      />
      {/* Sixth line */}
      <path
        d="m 195.84785,76.320979 h -4.608 V 0 h 4.608 z"
        fill={step === 3 ? activeColor : defaultColor}
      />
      {/* Fifth line */}
      <path
        d="m 157.99986,76.320979 h -4.608 V 35.17899 h 4.608 z"
        fill={defaultColor}
      />
      {/* Fourth line */}
      <path
        d="m 120.15187,76.320979 h -4.608 V 0 h 4.608 z"
        fill={step === 2 ? activeColor : defaultColor}
      />
      {/* Third line */}
      <path
        d="M 82.303877,76.320979 H 77.695878 V 35.17899 h 4.607999 z"
        fill={defaultColor}
      />
      {/* Seventh line */}
      <path
        d="m 233.69583,76.320979 h -4.60799 V 35.17899 h 4.60799 z"
        fill={defaultColor}
      />
      {/* Eighth line */}
      <path
        d="m 271.54382,76.320979 h -4.60799 V 0 h 4.60799 z"
        fill={step === 4 ? activeColor : defaultColor}
      />
      {/* Twelveth line */}
      <path
        d="m 422.93578,76.320979 h -4.608 V 0 h 4.608 z"
        fill={step === 6 ? activeColor : defaultColor}
      />
      {/* Eleventh line */}
      <path
        d="m 385.08779,76.320979 h -4.608 V 35.17899 h 4.608 z"
        fill={defaultColor}
      />
      {/* Tenth line */}
      <path
        d="m 347.2398,76.320979 h -4.608 V 0 h 4.608 z"
        fill={step === 5 ? activeColor : defaultColor}
      />
      {/* Nineth line */}
      <path
        d="m 309.39181,76.320979 h -4.608 V 35.17899 h 4.608 z"
        fill={defaultColor}
      />
      <path
        d="M 0,95.499993 H 422.93588"
        fill="none"
        stroke={step > 6 ? activeColor : defaultColor}
        strokeWidth="5"
      />
      {step <= 6 && step > 0 && (
        <path
          d="m 42.15189,101.49999 c 3.313999,0 5.999998,-2.685996 5.999998,-5.999995 0,-3.313999 -2.685999,-5.999999 -5.999998,-5.999999 -3.313999,0 -5.999998,2.686 -5.999998,5.999999 0,3.313999 2.685999,5.999995 5.999998,5.999995 z"
          fill="white"
          stroke={activeColor}
          strokeWidth="5"
          style={{
            transform: `translateX(${(step - 1) * 75.6}px)`,
          }}
        />
      )}
    </svg>
  )
}

export default ProgressBar
